import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
}

export const AvailableFlags = {
  "Testing|UnusedFakeTestFlagFalse": false,
  "Testing|UnusedFakeTestFlagTrue": true,
};

export type FeatureFlags = {
  features: typeof AvailableFlags;
};

export const FeatureFlags = React.createContext<FeatureFlags>({
  features: AvailableFlags,
});

export function useFeatureFlags(): FeatureFlags {
  const context = useContext(FeatureFlags);
  if (context === undefined) {
    throw new Error("useFeatureFlags must be used within a FeatureFlags");
  }

  return context;
}

export const FeatureFlagsProvider: React.FC<Props> = ({ children }: Props) => {
  const { hash } = useLocation();

  const flags = Object.assign({}, AvailableFlags);

  // Object.keys returns string[]
  (Object.keys(flags) as Array<keyof typeof AvailableFlags>).forEach((key) => {
    // Only check the URL for flags that are off by default
    if (flags[key] === false) flags[key] = hash.includes(`FeatureFlag|${key}`);
  });

  return (
    <FeatureFlags.Provider
      value={{
        features: flags,
      }}
    >
      {children}
    </FeatureFlags.Provider>
  );
};

export const excludedJurisdictions = [
  "US-HI",
  "US-KS",
  "US-MI",
  "US-NE",
  "MC",
  "JM",
  "ZA",
];
export const highlightedJurisdictions = ["IE", "GB", "LU", "NL", "SE"];
export const manualFilingRetrievalJurisdictions = ["IT"];
export const manualRetrievalJurisdictions = [
  "VG",
  "BG",
  "MD",
  "MM",
  "US-GA",
  "US-MT",
  "US-UT",
  "US-NV",
  "NP",
  "US-AR",
  "US-VT",
  "US-OH",
  "US-IL",
  "US-OK",
  "US-MO",
  "US-LA",
];

import React, { useEffect } from "react";
import qs from "query-string";
import {
  Box,
  CircularProgress,
  Link,
  Typography,
  Snackbar,
} from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import { Filing as FilingType } from "contexts/CompanyContext/types";
import Button from "components/Button/Button";
import { mid, dark } from "pages/variables";
import { WaitTime } from "./WaitTime";
import { toDisplayDate } from "utils/dates";
import { useCreditContext } from "contexts/CreditContext";
import { useApi } from "hooks/useApi";
import { IProductOrderResponse } from "components/FilingsPaper/types";
import { useCompanyContext } from "contexts/CompanyContext";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CustomerReferenceDialog from "./CustomerReferenceDialog";
import { Userpilot } from "userpilot";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

type Props = {
  data: FilingType;
};

export const getDisableDialogSetting = (): boolean => {
  return JSON.parse(localStorage.getItem("disableFilingDialog") || "false");
};
export const setDisableDialogSetting = (newVal: boolean) => {
  localStorage.setItem("disableFilingDialog", JSON.stringify(newVal));
};

const Filing: React.FC<Props> = ({
  data: {
    id,
    title,
    deliveryTimeMinutes,
    datePublished,
    creditCost,
    filingSample,
    countryISO,
  },
}) => {
  const isManual = id.startsWith("ROWOFF");
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);

  const [customerReferenceOpen, setCustomerReferenceOpen] =
    React.useState<boolean>(false);

  const [disableCustomerReferenceDialog, setDisableCustomerReferenceDialog] =
    React.useState<boolean>(getDisableDialogSetting());

  const [customerRef, setCustomerRef] = React.useState<string>("");

  const { companyProfile } = useCompanyContext();
  const { refetchCredits, credits } = useCreditContext();

  // Setup product call for later. This places the actual order
  // Disabled by default, called via refetch
  const productOrderParams = qs.stringify(
    {
      companyName: companyProfile.name,
      companyNumber: companyProfile.code,
      companyAddress: isManual
        ? companyProfile.addresses[0]?.addressInOneLine
        : "",
      displayDate: datePublished,
      customerRef: customerRef,
    },
    { skipNull: true },
  );

  const {
    data: productOrderData,
    error: productOrderError,
    isLoading: productOrderIsLoading,
    refetch: orderProductRefetch,
  } = useApi<IProductOrderResponse>(
    `/productorder/${countryISO}/${id}?${productOrderParams}`,
    {
      enabled: false,
      onSuccess: () => {
        setSnackbarOpen(true);
        refetchCredits();
      },
      onError: () => {
        setSnackbarOpen(true);
      },
    },
  );

  // Single entry point to allow us to track it
  const orderProductCall = () => {
    Userpilot.track("Filing Ordered");
    return orderProductRefetch();
  };

  const productOrdered = Boolean(
    !productOrderIsLoading && productOrderData && !productOrderError,
  );

  useEffect(() => {
    if (customerReferenceOpen) Userpilot.track("Filing Reference Opened");
  }, [id, customerReferenceOpen]);

  const notEnoughCredits =
    credits && credits.remainingCredits != undefined
      ? credits.remainingCredits < creditCost
      : false;

  const filingOrderButtonClicked = () => {
    Userpilot.track("Filing Button Clicked");

    // If it costs money and they have the dialog enabled, check there first
    if (creditCost > 0 && !getDisableDialogSetting())
      setCustomerReferenceOpen(true);
    else orderProductCall();
  };

  const getButtonContent = () => {
    if (productOrderIsLoading) {
      return (
        <>
          <CircularProgress color="inherit" size="small" />
          Loading...
        </>
      );
    }

    if (productOrdered) return "Ordered";
    if (notEnoughCredits) return "Not enough credits";
    if (creditCost === 0) return "Order";

    return `Order for ${creditCost} Credit${creditCost > 1 ? "s" : ""}`;
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleDisableCustomerReferenceDialog = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDisableCustomerReferenceDialog(event.target.checked);
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "25px",
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      })}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Typography fontSize="1.125rem" fontFamily="Sohne" color="primary.dark">
          {title}
        </Typography>
        <Button
          disabled={productOrdered || productOrderIsLoading || notEnoughCredits}
          sx={{
            whiteSpace: "nowrap",
            minWidth: "113px",
            alignSelf: "flex-start",
            marginLeft: 1,
            color: "white",
          }}
          data-testid={"order-button-" + id}
          onClick={filingOrderButtonClicked}
        >
          {getButtonContent()}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingTop: 1,
          paddingBottom: "14px",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <WaitTime
            timeInMinutes={
              deliveryTimeMinutes ? parseInt(deliveryTimeMinutes) : null
            }
          />
          {datePublished && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CalendarMonth fontSize="small" sx={{ color: mid }} />
              <Typography color={dark} sx={{ fontSize: "0.875rem" }}>
                {toDisplayDate(datePublished)}
              </Typography>
            </Box>
          )}
        </Box>
        {filingSample && (
          <Link
            variant="body2"
            href={filingSample}
            target="_blank"
            color={dark}
            sx={{ textDecoration: "none" }}
          >
            Sample
          </Link>
        )}
      </Box>
      {/* TODO:: Show duplicate order check error here? */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={productOrderError ? "error" : "success"}
          sx={{ width: "100%", color: "white" }}
        >
          {productOrderError
            ? "There was an error."
            : `Ordered "${title}". It’s on its way to your registered email
          address.`}
        </Alert>
      </Snackbar>
      <CustomerReferenceDialog
        dialogOpen={customerReferenceOpen}
        setDialogOpen={(val: React.SetStateAction<boolean>) => {
          setCustomerReferenceOpen(val);

          setDisableCustomerReferenceDialog(getDisableDialogSetting());
        }}
        creditCost={creditCost}
        customerRef={customerRef}
        setCustomerRef={setCustomerRef}
        disableDialog={disableCustomerReferenceDialog}
        handleDisableDialog={handleDisableCustomerReferenceDialog}
        doAction={orderProductCall}
        persistDisableDialogSetting={() => {
          if (disableCustomerReferenceDialog)
            Userpilot.track("Pop Up 'Dont Show Me Again' set");
          setDisableDialogSetting(disableCustomerReferenceDialog);
        }}
        orderType="document"
      ></CustomerReferenceDialog>
    </Box>
  );
};

export default Filing;
